import { useState, useRef, useEffect, ReactNode, FC } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Dropdown.scss";

interface DropdownProps extends BaseProps {
  children: ReactNode;
  list: ReactNode;
}

type DropdownStatus = "opened" | "closed" | "init";

const DropdownV2: FC<DropdownProps> = ({ children, list, ...rest }) => {
  const [status, setStatus] = useState<DropdownStatus>("init");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setStatus((prev) => {
      switch (prev) {
        case "init":
          return "opened";
        case "closed":
          return "opened";
        case "opened":
          return "closed";
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setStatus((prev) => {
        return prev === "init" ? "init" : "closed";
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={classNames("dropdown", rest.className)} style={rest.style} ref={dropdownRef} onClick={handleToggle}>
      <div>{children}</div>
      <div className={`dropdown-list ${status}`}>{list}</div>
    </div>
  );
};

export default DropdownV2;

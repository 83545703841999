import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";
import "./ProductsSkeyAccessControlHero.scss";
import { menuLinks } from "../../../data/menuLinks";

const PRODUCTS = 0;
const PRODUCTS_PATH = menuLinks.products.paths[PRODUCTS];

const ProductsSkeyAccessControlHero = () => {
  return (
    <div id="products-skey-access-control-hero">
      <Container fluid="xxl">
        <img
          width={900}
          height={607}
          src="/images/access-control-hero-img-desktop.png"
          alt="access-control"
          className="access-control-hero desktop-xl"
        />
        <img
          width={375}
          height={375}
          src="/images/access-control-hero-img-mobile.png"
          alt="access-control"
          className="access-control-hero mobile-xl"
        />
        <Container fluid="xl">
          <Row className="row-hero">
            <Col xs={12} md={6} className="content-col">
              <h1 className="header">Skey Access Control</h1>
              <Space space="space-lg" className="desktop-xl" />
              <Space space="space-md" className="mobile-xl" />
              <p className="s1 subheader">
                Access controllers are virtual keys to various types of devices, such as doors, gates, cars, as well as
                mechanisms based on IoT technology.
              </p>
              <Space space="space-xxl" />
              <Link variant="primary" className="buttonHero" url={PRODUCTS_PATH} target="_self">
                Show all products
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyAccessControlHero;

import { CSSProperties, ReactNode } from "react";

import { BaseProps } from "../../../shared/interfaces/BaseProps";
import { classNames } from "../../../utils/classNames";

import "./Stack.scss";

export interface IStack extends BaseProps {
  rowGap?: number;
  fullSize?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  children: ReactNode;
}

const Stack = ({
  rowGap = 0,
  children,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  fullHeight,
  fullSize,
  fullWidth,
  className,
  style
}: IStack) => {
  const classes = classNames(
    "stack",
    className,
    fullSize ? "full-size" : "",
    fullWidth ? "full-width" : "",
    fullHeight ? "full-height" : ""
  );

  return (
    <div
      className={classes}
      style={{
        rowGap: `${rowGap}px`,
        justifyContent,
        alignItems,
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default Stack;

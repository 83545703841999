import { Container } from "react-bootstrap";

import { IPanelCard } from "../../../shared/interfaces/IPanelCard";
import { administrationPanelCard } from "../../../data/administrationPanelCard";
import { TIX_EVENT } from "../../../constants";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./AdministrationPanelCard.scss";

const AdministrationPanelCard = () => {
  return (
    <div className="skey-tix-administrations-cards">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="cards-wrapper">
            {administrationPanelCard.map(({ image, title }: IPanelCard) => {
              return (
                <div key={Math.random()} className="administration-card">
                  <img width={300} alt={title} height={143} src={image} className="card-image" />
                  <Space space={"space-md"} />
                  <span>{title}</span>
                </div>
              );
            })}

            <div className="administration-card">
              <p className="s1">Log in to create an event</p>
              <Space space="space-xl" />
              <span>See how convenient and intuitive the SkeyTix system is</span>
              <Space space="space-xl" />
              <Link
                imgWidth={16}
                imgHeight={15}
                url={TIX_EVENT}
                target="_blank"
                variant="trietary"
                size="size-small"
                icon="/images/go-to.svg"
              >
                log in
              </Link>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};
export default AdministrationPanelCard;

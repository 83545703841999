export interface SkeyPartnerships {
  img: string;
  url: string;
}

export const skeyPartnershipsImages: SkeyPartnerships[] = [
  {
    img: "/images/partnership-copernicoin.svg",
    url: "https://copernicoin.pl/"
  },
  {
    img: "/images/partnership-mazury-airport.svg",
    url: "https://mazuryairport.pl/"
  },
  {
    img: "/images/partnership-orange.svg",
    url: "https://www.orange.pl/"
  },
  /*{
    img: "/images/partnership-kleiberit.png",
    url: "https://www.kleiberit.com/en/home"
  },*/
  {
    img: "/images/partnership-chainlink.svg",
    url: "https://chain.link/"
  },
  {
    img: "/images/partnership-teltonika.svg",
    url: "https://teltonika-networks.com/"
  },
  {
    img: "/images/partnership-go2nft.svg",
    url: "https://go2nft.io/"
  },
  {
    img: "/images/partnership-ferrumNetwork.svg",
    url: "https://ferrum.network/"
  },
  {
    img: "/images/partnership-waste24.svg",
    url: "https://waste24.net/"
  },
  {
    img: "/images/partnership-caruma.svg",
    url: "https://caruma.io/"
  }
];

import { IImage } from "./Image.interface";

import "./Image.scss";

const Image = ({
  imgPath,
  asLink,
  href,
  className = "",
  alt,
  imgWidth,
  imgHeight,
  asButton = false,
  onClick = () => {},
  onBlur = () => {}
}: IImage) => {
  const img = <img className={`image ${className}`} alt={alt} src={imgPath} width={imgWidth} height={imgHeight} />;

  if (asLink) {
    return (
      <a href={href} rel="noreferrer noopener" className={`as-link ${className}`}>
        {img}
      </a>
    );
  }

  if (asButton) {
    return (
      <button onClick={onClick} onBlur={onBlur} className={className}>
        {img}
      </button>
    );
  }

  return <>{img}</>;
};

export default Image;

import { Container, Row, Col } from "react-bootstrap";
import { IProductSkeyCard } from "../../../shared/interfaces/IProductSkeyCard";
import ProductSkeyCard from "../../components/molecules/ProductSkeyCard/ProductSkeyCard";
import { productsOfSkeyNetwork } from "../../../data/productsOfSkeyNetwork";
import "./ProductsSkeyCards.scss";

const ProductsSkeyCards = () => {
  return (
    <div id="products-skey-cards">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row>
            {productsOfSkeyNetwork.map((product: IProductSkeyCard) => {
              return (
                <Col xs={12} md={6} className="card-col" key={Math.random()}>
                  <ProductSkeyCard
                    title={product.title}
                    text={product.text}
                    imgPath={product.imgPath}
                    mobileImgPath={product.mobileImgPath}
                    url={product.url}
                    linkLabel={product.linkLabel}
                    linkImgPath={product.linkImgPath}
                    disabled={product.disabled}
                  />
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyCards;

import { useFormsControll } from "../../../../contexts/FormsControllContext";
import { IChildren } from "../../../../shared/interfaces/IChildren";
import "./Backdrop.scss";

const Backdrop = ({ children }: IChildren) => {
  const { closeAll } = useFormsControll();
  return (
    <div className="skey-backdrop">
      {children}
      <div className="backdrop-filler" onClick={closeAll}></div>
    </div>
  );
};

export default Backdrop;

import { Container } from "react-bootstrap";

import { TIX_URL } from "../../../constants";
import { skeyTixApp } from "../../../data/skeyTixApp";
import { productsSkeyTixOperation } from "../../../data/productsSkeyTixOperation";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./SkeyTixOperation.scss";

const SkeyTixOperation = () => {
  return (
    <div id="skey-tix-operation" className="skey-tix-operation">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="skey-tix-operation-container">
            <div className="operation-header">
              <h2>Simple operation</h2>
              <Space space="space-lg" />

              <p className="s1">Check how simple the generation and management of SkeyTix is.</p>
            </div>

            <div className="operation-main">
              <div>
                <div className="operation-cards">
                  {productsSkeyTixOperation.map((card) => (
                    <div key={Math.random()} className="operation-card">
                      <img height={74} width={74} src={card.img} alt="card" className="image-tix" />
                      <Space space="space-xs" />
                      <p className="s1 subheader">{card.header}</p>
                      <Space space="space-sm" />
                      <p className="s2 subheader operation">{card.subheader}</p>
                    </div>
                  ))}
                </div>

                <div className="operation-links">
                  <p className="s2">Check out the ticket validation app:</p>
                  <div className="stores-links">
                    {skeyTixApp.map(({ url, logoPath }) => {
                      return (
                        <a key={Math.random()} href={url} target="blank" rel="norefferer noopener">
                          <img
                            width={100}
                            height={31}
                            src={logoPath}
                            alt="mobile app store"
                            className="store-app-link"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="operation-main-image">
                <img
                  width={425}
                  height={270}
                  src="/images/skey-tix-card-desktop.png"
                  alt="skey tix simple operation"
                  className="desktop-lg image-tix-app"
                />
                <img
                  width={286}
                  height={181}
                  src="/images/skey-tix-card-mobile.png"
                  alt="skey tix simple operation"
                  className="mobile-lg image-tix-app"
                />
                <Space space="space-xl" className="desktop-lg" />
                <Space space="space-sm" className="mobile-lg" />
                <Link
                  url={TIX_URL}
                  variant="trietary"
                  size="size-small"
                  icon="/images/go-to.svg"
                  imgWidth={16}
                  imgHeight={15}
                  className="d-unset"
                >
                  read how it works
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixOperation;

import { Col, Container, Row } from "react-bootstrap";
import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";
import { useIsMobile } from "../../../hooks/useIsMobile";

import "./OverviewHero.scss";

const OverviewHero = () => {
  const isMobile = useIsMobile("xl");
  return (
    <div id="overview-hero">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <Row className={"row-hero"}>
            <Col xs={12} md={6} className={"content-col"}>
              <h1 className={"ecosystemHero"}>Fast, scalable, and low-cost blockchain ecosystem.</h1>
              <Space space={"space-lg"} className={"desktop-xl"} />
              <Space space={"space-md"} className={"mobile-xl"} />
              <p className="s1 subheader hero-adaptable">Build adaptable products, services, and user-friendly apps.</p>
              <Space space={"space-xxl"} />
              <Link variant={"primary"} className={"buttonHero"} url={"/development"} target={"_self"}>
                Build on Skey Network
              </Link>
            </Col>
          </Row>
        </Container>
        {isMobile && (
          <img
            src={"/images/overview-bg-blocks-mobile.png"}
            width="796"
            height="941"
            alt={"logo"}
            className={"overview-bg-blocks"}
            loading="lazy"
          />
        )}
        {!isMobile && (
          <img
            src={"/images/overview-bg-blocks-desktop.png"}
            width="867"
            height="1025"
            alt={"logo"}
            className={"overview-bg-blocks"}
            loading="lazy"
          />
        )}
      </Container>
    </div>
  );
};

export default OverviewHero;

import { IContadctProps } from "../../../shared/interfaces/IContactSection";
import Button from "../../components/atoms/Button/Button";
import Space from "../../components/atoms/Spacing/Space";
import "./ContactSection.scss";

const ContactSection = ({ header, subheader, buttonLabel, action }: IContadctProps) => {
  return (
    <div className="contact-section">
      <h2 className="header">{header}</h2>
      <Space space="space-lg" />
      <p className="s2 subheader">{subheader}</p>
      <Space space="space-lg" />
      <Button variant="primary" size="size-normal" className="button-contact" onClick={action}>
        {buttonLabel}
      </Button>
    </div>
  );
};

export default ContactSection;

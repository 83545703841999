import OverviewDocumentation from "../../sections/OverviewDocumentation/OverviewDocumentation";
import OverviewPartnerships from "../../sections/OverviewPartners/OverviewPartnerships";
import OverviewHero from "../../sections/OverviewHero/OverviewHero";
import OverviewBlockchain from "../../sections/OverviewBlockchain/OverviewBlockchain";
import OverviewProducts from "../../sections/OverviewProducts/OverviewProducts";
import OverviewSkeyEcosystem from "../../sections/OverviewSkeyEcosystem/OverviewSkeyEcosystem";
import OverviewSkeyNetworkToday from "../../sections/OverviewSkeyNetworkToday/OverviewSkeyNetworkToday";
import OverviewDex from "../../sections/OverviewDex/OverviewDex";
import OverviewSkeyBoxWidget from "../../sections/OverviewSkeyBoxWidget/OverviewSkeyBoxWidget";
import { HeightProvider, HeightProviderCtx } from "../../../contexts/HeightProvider";
import OverviewBuyBox from "../../sections/OverviewBuyBox/OverviewBuyBox";

import "./Overview.scss";

const Overview = () => {
  return (
    <HeightProvider>
      <HeightProviderCtx.Consumer>
        {(ctx) => {
          return (
            <section id="overview" ref={ctx.tagRef}>
              <OverviewHero />
              <OverviewPartnerships />
              <OverviewBuyBox />
              <OverviewDex />
              <OverviewDocumentation />
              <OverviewProducts />
              <OverviewSkeyEcosystem />
              <OverviewBlockchain />
              <OverviewSkeyNetworkToday />
              <OverviewSkeyBoxWidget />
            </section>
          );
        }}
      </HeightProviderCtx.Consumer>
    </HeightProvider>
  );
};

export default Overview;

export const productsSkeyBoxIntegration = [
  {
    img: "/images/product-operation1.png",
    header: "Create an account",
    subheader: "Create an account using your company data (e-mail from its domain)."
  },
  {
    img: "/images/product-operation2.png",
    header: "Create the company's profile",
    subheader: "Fill in the profile with company data, and confirm the permission for data collection."
  },
  {
    img: "/images/product-operation3.png",
    header: "Choose your services",
    subheader:
      "Indicate which services you want to use. Once you have an account on the platform, you can activate new services anytime."
  },
  {
    img: "/images/product-operation4.png",
    header: "Place your order",
    subheader: "Choose your desired services and pay the activation fee and annual service maintenance (see the offer)."
  },
  {
    img: "/images/product-operation5.png",
    header: "Activated and ready to use",
    subheader: "When the activation is done, use the available SkeyBox modules"
  }
];

import { ITextRole } from "../../../../shared/interfaces/ITextRole";
import { IInteractiveElement } from "../../../../shared/interfaces/IInteractiveElement";
import { IChildren } from "../../../../shared/interfaces/IChildren";
import { ITextWeight } from "../../../../shared/interfaces/ITextWeight";
import { IWithIcon } from "../../../../shared/interfaces/IWithIcon";
import { IWithBadge } from "../../../../shared/interfaces/IWithBadge";
import Badge from "../Badge/Badge";
import HoverIcon from "../HoverIcon/HoverIcon";
import { IActive } from "../../../../shared/interfaces/IActive";
import "./InteractiveText.scss";

const InteractiveText = ({
  textRole,
  variant = "default",
  children,
  imgWidth,
  imgHeight,
  weight = "light",
  className = "",
  iconPath = "",
  badgeUrl = "",
  active = false
}: IInteractiveElement & ITextRole & IChildren & ITextWeight & IWithIcon & IWithBadge & IActive) => {
  const Text = ({ children }: IChildren) => {
    const getTextType = () => {
      switch (textRole) {
        case "h1":
          return <h1 className={variant}>{children}</h1>;
        case "h2":
          return <h2 className={variant}>{children}</h2>;
        case "h3":
          return <h3 className={variant}>{children}</h3>;
        case "h4":
          return <h4 className={variant}>{children}</h4>;
        case "p":
          return <p className={variant}>{children}</p>;
        case "ps1":
          return <p className={`s1 ${variant}`}>{children}</p>;
        case "ps2":
          return <p className={`s2 ${variant}`}>{children}</p>;
        case "ps2-other":
          return <p className={`s2-other ${variant}`}>{children}</p>;
      }
    };

    return (
      <span className={`interactive-text ${variant} ${weight} ${className} ${active ? "active" : ""}`}>
        {getTextType()}
        {iconPath && (
          <HoverIcon iconPath={iconPath} imgHeight={imgHeight} imgWidth={imgWidth} isHover={active} className="ml-xs" />
        )}
        {badgeUrl && <Badge badgeUrl={badgeUrl} className="badge-position" />}
      </span>
    );
  };

  return <Text>{children}</Text>;
};

export default InteractiveText;

import { ITechButton } from "../shared/interfaces/ITechButton";
import {
  PRODUCT_SKEY_TIX_GITBOOK,
  PRODUCT_SKEY_TIX_PANEL_MANUAL,
  PRODUCT_SKEY_TIX_VIDEO
} from "../constants";

export const productsSkeyTixLinks: ITechButton[] = [
  {
    label: "Show Gitbook",
    link: PRODUCT_SKEY_TIX_GITBOOK,
    iconPath: "/images/gitbook.svg"
  },
  {
    label: "Watch Video",
    link: PRODUCT_SKEY_TIX_VIDEO,
    iconPath: "/images/video.svg"
  },
  {
    label: "Read how it works",
    link: PRODUCT_SKEY_TIX_PANEL_MANUAL,
    iconPath: "/images/whitepaper.svg"
  }
];

import { useState } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { IProductSkeyCard } from "../../../../shared/interfaces/IProductSkeyCard";
import Space from "../../atoms/Spacing/Space";
import InteractiveText from "../../atoms/InteractiveText/InteractiveText";

import "./ProductSkeyCard.scss";

const ProductSkeyCard = ({
  title,
  text,
  imgPath,
  url,
  linkLabel,
  disabled,
  linkImgPath,
  mobileImgPath
}: IProductSkeyCard) => {
  const [hover, setHover] = useState(false);

  const getLink = () => {
    return url?.startsWith("http") ? (
      <a
        href={url || ""}
        className={`product-skey-card-container ${disabled ? "disabled" : ""}`}
        rel="noreferrer noopener"
        target="_blank"
      >
        <div style={{ position: "relative", height: "250px", width: "100%" }}>
          {!mobileImgPath || (!isMobile && !!mobileImgPath) ? (
            <img
              width="552"
              height="250"
              src={imgPath}
              alt="Product of Skey Network Blockchain"
              className="header-image"
            />
          ) : isMobile ? (
            <img
              width={340}
              height={236}
              src={mobileImgPath}
              alt="Product of Skey Network Blockchain"
              className="header-image"
            />
          ) : (
            <></>
          )}
          <div className="header-imageOverlay" />
        </div>
        <div className="content">
          <h2 className="title-card">{title}</h2>
          <Space space="space-md" className="desktop-lg" />
          <Space space="space-sm" className="mobile-lg" />
          <p className="s2">{text}</p>
          <Space space="space-md" />
          <InteractiveText
            imgWidth={16}
            imgHeight={15}
            variant="default"
            textRole="ps2"
            weight="regular"
            iconPath={linkImgPath}
            className={`link ${!url ? "link-disabled" : "interactive-text default"}`}
            active={hover}
          >
            {linkLabel}
          </InteractiveText>
        </div>
      </a>
    ) : (
      <Link
        to={url || ""}
        className={`product-skey-card-container ${disabled ? "disabled" : ""}`}
        rel="noreferrer noopener"
      >
        <div style={{ position: "relative", height: "250px", width: "100%" }}>
          {!mobileImgPath || (!isMobile && !!mobileImgPath) ? (
            <img
              width="552"
              height="250"
              src={imgPath}
              alt="Product of Skey Network Blockchain"
              className="header-image"
            />
          ) : isMobile ? (
            <img
              width={340}
              height={236}
              src={mobileImgPath}
              alt="Product of Skey Network Blockchain"
              className="header-image"
            />
          ) : (
            <></>
          )}
          <div className="header-imageOverlay" />
        </div>
        <div className="content">
          <div>
            <h2 className="title-card">{title}</h2>
            <Space space="space-md" className="desktop-lg" />
            <Space space="space-sm" className="mobile-lg" />
            <p className="s2">{text}</p>
            <Space space="space-md" />
          </div>

          <div>
            <InteractiveText
              imgWidth={16}
              imgHeight={15}
              variant="default"
              textRole="ps2"
              weight="regular"
              iconPath={linkImgPath}
              className={`link ${!url ? "link-disabled" : "interactive-text default"}`}
              active={hover}
            >
              {linkLabel}
            </InteractiveText>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div
      className={`product-skey-card ${disabled ? "disabled" : ""}`}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}
    >
      {getLink()}
    </div>
  );
};

export default ProductSkeyCard;

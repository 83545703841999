import { Col, Container, Row } from "react-bootstrap";

import { menuLinks } from "../../../data/menuLinks";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./ProductsSkeyTixHero.scss";

const PRODUCTS = 0;
const PRODUCTS_PATH = menuLinks.products.paths[PRODUCTS];

const ProductsSkeyTixHero = () => {
  return (
    <div id="products-skey-tix-hero" className="products-skey-tix-hero">
      <Container fluid="xxl">
        <img
          width={858}
          height={650}
          src="/images/skey-tix-img-hero-desktop.png"
          alt="skey-tix"
          className="skey-tix-hero desktop-xl"
        />
        <img
          width={535}
          height={453}
          src="/images/skey-tix-img-hero-mobile.png"
          alt="skey-tix"
          className="skey-tix-hero mobile-xl"
        />

        <Container fluid="xl">
          <Row className="row-hero">
            <Col xs={12} sm={8} lg={6} className="row-hero-col">
              <h1 className="header-skey-tix">Skey TIX</h1>

              <Space space="space-lg" className="desktop-xl" />
              <Space space="space-md" className="mobile-xl" />

              <p className="s1">
                SkeyTix is a tool integrated with your system - it transfers products to the blockchain world using NFT
                and allows you to create a unique event. Thanks to this technology, you get anti-counterfeit protection
                and access to monitoring tools.
              </p>
              <Space space="space-xxl" />

              <div className="buttons">
                <Link variant="primary" url={PRODUCTS_PATH} target="_self">
                  Show all products
                </Link>
                <Link variant="secondary" url="#skey-tix-poap" target="_self">
                  Skey PoAP
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyTixHero;

import {
  DISCORD,
  FACEBOOK,
  KAKAO_TALK,
  MEDIUM,
  REDDIT,
  TWITTER,
  YOUTUBE
} from "../constants";

export const socialMedia = [
  {
    logoPath: "/images/twitter-hover.svg",
    url: TWITTER
  },
  {
    logoPath: "/images/youtube-hover.svg",
    url: YOUTUBE
  },
  {
    logoPath: "/images/reddit-hover.svg",
    url: REDDIT
  },
  {
    logoPath: "/images/facebook-hover.svg",
    url: FACEBOOK
  },
  {
    logoPath: "/images/discord-hover.svg",
    url: DISCORD
  },
  {
    logoPath: "/images/kakaotalk-hover.svg",
    url: KAKAO_TALK
  },
  {
    logoPath: "/images/medium-hover.svg",
    url: MEDIUM
  }
];

import { Col, Container, Row } from "react-bootstrap";

import Space from "../../components/atoms/Spacing/Space";
import Button from "../../components/atoms/Button/Button";
import Link from "../../components/atoms/Link/Link";

import { useFormsControll } from "../../../contexts/FormsControllContext";
import { useDefaultProduct } from "../../../contexts/DefaultProductContext";

import { SKEY_WIZARD } from "../../../constants";
import "./ProductsSkeyBoxHero.scss";

const ProductsSkeyBoxHero = () => {
  const { openModulesForm } = useFormsControll();
  const { handleDefaultProduct } = useDefaultProduct();

  const orderAction = () => {
    handleDefaultProduct("Skey Box");
    openModulesForm();
  };

  return (
    <div id="products-skey-box-hero">
      <Container fluid="xxl">
        <img
          width={530}
          height={651}
          src="/images/skey-box-img-hero-desktop.png"
          alt="skey-box"
          className="skey-box-hero desktop-xl"
        />
        <img
          width={340}
          height={418}
          src="/images/skey-box-img-hero-mobile.png"
          alt="skey-box"
          className="skey-box-hero mobile-xl"
        />
        <Container fluid="xl">
          <Row className="row-box-hero">
            <Col xs={12} md={6} className="content-col">
              <h2 className="header">Skey BOX</h2>
              <Space space="space-lg" className="desktop-xl" />
              <Space space="space-md" className="mobile-xl" />
              <p className="s2 subheader">
                SkeyBox is a service platform that operates on the Blockchain as a Service (BaaS) model. It serves as a
                gateway to a decentralized, distributed record of all operations and transactions, providing undeniable
                data about the company that cannot be hacked, deleted, or falsified. The use of blockchain technology
                guarantees the immutability and non-repudiation of all data pertaining to your company. This feature
                provides your conscientious customers with proof of your reliability and responsibility.
              </p>
              <Space space="space-xxl" className="desktop-xl" />
              <Space space="space-lg" className="mobile-xl" />
              <div className="buttons">
                <Link variant="primary" url={SKEY_WIZARD} target="_blank">
                  Login to Skey BOX
                </Link>

                <Button variant="secondary" size="size-normal" onClick={orderAction}>
                  Request an offer
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProductsSkeyBoxHero;

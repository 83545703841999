import { IProductSkeyCard } from "../shared/interfaces/IProductSkeyCard";
import { menuLinks } from "./menuLinks";

const BLOCKCHAIN = 1;
const ACCESS_CONTROL = 2;
const SKEY_BOX = 3;
const SKEY_TIX = 4;

const BLOCKCHAIN_PATH = menuLinks.products.paths[BLOCKCHAIN];
const ACCESS_CONTROL_PATH = menuLinks.products.paths[ACCESS_CONTROL];
const SKEY_BOX_PATH = menuLinks.products.paths[SKEY_BOX];
const SKEY_TIX_PATH = menuLinks.products.paths[SKEY_TIX];

export const productsOfSkeyNetwork: IProductSkeyCard[] = [
  {
    title: "Blockchain",
    text: "Blockchain Skey is a data bus for emerging digital services and products. We provide full support, products, and tools.",
    imgPath: "/images/products-skey-card1.jpeg",
    // mobileImgPath: "/images/products-skey-card1-mobile.jpeg",
    url: BLOCKCHAIN_PATH,
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    disabled: false
  },
  {
    title: "Skey Access Control",
    text: "Skey Network provides a technology that combines Oracle, DeFi, NFT, and IoT devices with blockchain, creating Blockchain of Things",
    imgPath: "/images/products-skey-card2.jpeg",
    // mobileImgPath: "/images/products-skey-card2-mobile.jpeg",
    url: ACCESS_CONTROL_PATH,
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    disabled: false
  },
  {
    title: "Skey Box",
    text: "Skey Box - a stored blockchain copy. It is your own gateway to the blockchain ecosystem",
    imgPath: "/images/products-skey-card3.jpeg",
    // mobileImgPath: "/images/products-skey-card3-mobile.jpeg",
    url: SKEY_BOX_PATH,
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    disabled: false
  },
  {
    title: "Skey Tix with Skey POAP",
    text: "The tool integrated with your system - it transfers products to the blockchain using NFT and allows you to create a unique event. Thanks to Skey POAP you can create digital mementos.",
    imgPath: "/images/products-skey-card4v2.jpg",
    // mobileImgPath: "/images/products-skey-card4-mobile.jpeg",
    url: SKEY_TIX_PATH,
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    disabled: false
  },
  {
    title: "Skey AI",
    text: "A revolutionized set of AI tools, optimizing the process of creating carbon certificates and other essential documents. Compliant with European Commission standards, our solutions actively contribute to sustainable development and environmental protection.",
    imgPath: "/images/products-skey-card5.png",
    mobileImgPath: "/images/products-skey-card5-mobile.png",
    // url: SKEY_TIX_PATH,
    linkLabel: "more info soon",
    linkImgPath: "/images/go-to.svg",
    disabled: true
  },
  {
    title: "Skey Payment Solution",
    text: "A system designed for banks, financial institutions, payment card operators, and other verified licensed users issuing Prepaid cards.",
    imgPath: "/images/products-skey-card6.png",
    mobileImgPath: "/images/products-skey-card6-mobile.png",
    // url: SKEY_TIX_PATH,
    linkLabel: "more info soon",
    linkImgPath: "/images/go-to.svg",
    disabled: true
  },
  {
    title: "Skey RWA",
    text: "It's a tool designed to simplify the tokenization of a variety of assets, such as parking spaces, advertising media, or farmland. It offers a comprehensive infrastructure, making the tokenization process simple and accessible to everyone.",
    imgPath: "/images/card-rwa.jpg",
    mobileImgPath: "/images/card-rwa-mobile.jpg",
    // url: SKEY_TIX_PATH,
    linkLabel: "more info soon",
    linkImgPath: "/images/go-to.svg",
    disabled: true
  }
];

export const skeyNetworkProductsNames = productsOfSkeyNetwork.map((product) => product.title);

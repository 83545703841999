import { useEffect, useState } from "react";
import { CRYPTO_CODE, FiatCodes, fiatsProps } from "../constants/constants";
import { ariApiClientInstance } from "../../../../api/ariApiClientInstance";
import useDebounce from "../../../../hooks/useDebounce";

const getFiatAmount = async (cryptoCode: string, fiatAmount: string, fiatCode: string) => {
  const response = await ariApiClientInstance.calculate(cryptoCode, fiatAmount, fiatCode);
  return response.data;
};

const isValidNumber = (amount: string) => {
  return !isNaN(Number(amount)) && Number(amount) > 0 && amount !== "";
};

interface Res {
  amount: number;
  code: "string";
  name: string;
}

const useBuyForm = () => {
  const [fiatAmount, setFiatAmount] = useState("");
  const updateFiatAmount = (amount: string) => setFiatAmount(amount);

  const [fiatCode, setFiatCode] = useState<FiatCodes>("EUR");
  const updateFiatCode = (fiatCode: FiatCodes) => setFiatCode(fiatCode);

  const [calculatedCrypto, setCalcCrypto] = useState<number | null>(null);

  const [oneFiatUnitPrice, updateOneFiatUnitPrice] = useState<null | number>(null);

  const debouncedFiatAmount = useDebounce<string>(fiatAmount, 400);

  const _triggerCalculate = async () => {
    try {
      const res = (await getFiatAmount(CRYPTO_CODE, debouncedFiatAmount, fiatCode)) as unknown as Res | undefined;
      if (!res?.amount) {
        return setCalcCrypto(null);
      }
      setCalcCrypto(res.amount);
      updateOneFiatUnitPrice(Number(debouncedFiatAmount) / res.amount);
    } catch (e) {
      console.error("Couldnt get calculated value");
    }
  };

  const initCalculate = async () => {
    const fiatAmountTest = "100";
    try {
      const res = (await getFiatAmount(CRYPTO_CODE, fiatAmountTest, fiatCode)) as unknown as Res | undefined;
      if (!res?.amount) {
        return setCalcCrypto(null);
      }
      // setCalcCrypto(res.amount);
      updateOneFiatUnitPrice(Number(fiatAmountTest) / res.amount);
    } catch (e) {}
  };

  const noGivenFiatAmount = fiatAmount === "0" || fiatAmount === "";
  const noGivenDebouncedFiatAmount = debouncedFiatAmount === "0" || debouncedFiatAmount === "";

  useEffect(() => {
    if (isNaN(Number(debouncedFiatAmount))) return;
    if (noGivenDebouncedFiatAmount) return;
    if (!isValidNumber(debouncedFiatAmount)) return;

    const asNumber = Number(fiatAmount);
    if (asNumber < activeFiatProps.minAmount) return;

    _triggerCalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiatCode, noGivenDebouncedFiatAmount, debouncedFiatAmount, fiatAmount]);

  useEffect(() => {
    if (noGivenFiatAmount) {
      setCalcCrypto(0);
    }
  }, [noGivenFiatAmount]);

  const activeFiatProps = fiatsProps[fiatCode];

  useEffect(() => {
    if (isNaN(Number(fiatAmount))) return;
    if (!isValidNumber(fiatAmount)) return;

    const asNumber = Number(fiatAmount);
    if (asNumber < activeFiatProps.minAmount) {
      setCalcCrypto(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiatCode, fiatAmount]);

  useEffect(() => {
    initCalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiatCode]);

  return {
    fiatAmount,
    updateFiatAmount,
    fiatCode,
    updateFiatCode,
    calculatedCrypto,
    debouncedFiatAmount,
    activeFiatProps,
    oneFiatUnitPrice
  };
};

export default useBuyForm;

import { ITechButton } from "../shared/interfaces/ITechButton";
import {
  PRODUCT_SKEY_ACCESS_GITBOOK,
  PRODUCT_SKEY_ACCESS_ORGANIZATION_MANAGER_MANUAL,
  PRODUCT_SKEY_ACCESS_SKEEPER_MANUAL,
  PRODUCT_SKEY_ACCESS_VIDEO
} from "../constants";

export const productSkeyAccessLinks: ITechButton[] = [
  {
    label: "Show Gitbook",
    link: PRODUCT_SKEY_ACCESS_GITBOOK,
    iconPath: "/images/gitbook.svg"
  },
  {
    label: "Watch Video",
    link: PRODUCT_SKEY_ACCESS_VIDEO,
    iconPath: "/images/video.svg"
  },
  {
    label: "Panel instructions",
    link: PRODUCT_SKEY_ACCESS_ORGANIZATION_MANAGER_MANUAL,
    iconPath: "/images/whitepaper.svg"
  },
  {
    label: "Skeeper instructions",
    link: PRODUCT_SKEY_ACCESS_SKEEPER_MANUAL,
    iconPath: "/images/whitepaper.svg"
  }
];

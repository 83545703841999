import { Container } from "react-bootstrap";

import { useCtxHeightProvider } from "../../../contexts/HeightProvider";
import Group from "../../../components/arrangement/Group/Group";
import InteractiveText from "../../components/atoms/InteractiveText/InteractiveText";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { scrollToView } from "../../../utils/scrollToView";
import Space from "../../components/atoms/Spacing/Space";

import Flex from "../../../components/arrangement/Flex/Flex";

import { copyInput } from "../../../services/copyInput";
import useBoolean from "../../../hooks/useBoolean";
import useTimeout from "../../../hooks/useTimeout";

import { WIDGET_URL } from "../../../constants";

import "./OverviewSkeyBoxWidget.scss";

const SECTION_ID = "widget-section";

const MAIL_ADDRESS = "box@skey.network";

const OverviewSkeyBoxWidget = () => {
  const tag = useCtxHeightProvider();
  const isMobile = useIsMobile("md");

  const [isVisible, show, hide] = useBoolean(false);
  useTimeout({ condition: isVisible, callback: hide, timeoutMs: 3000 });

  return (
    <div className="widget-section" id={SECTION_ID}>
      <div className="box-scroller-wrapper" style={{ height: tag.elementHeight }}>
        <div className="box-scroller">
          <div className="scroller-content">
            <button
              onClick={() => scrollToView(SECTION_ID)}
              style={{ height: "inherit", width: "inherit", cursor: "pointer" }}
              className="p-xs-2"
            >
              <Container fluid="xxl">
                <Container fluid="xl">
                  <Group justifyContent="space-between" fullSize>
                    <Group colGap={10} justifyContent={isMobile ? "space-between" : "flex-start"} fullWidth>
                      <p className="s2-other">Check the authenticity of a file!</p>
                      <InteractiveText
                        imgWidth={17}
                        imgHeight={16}
                        variant="default"
                        textRole="ps2-other"
                        weight="regular"
                        iconPath={"/images/icons/scroll-down.svg"}
                      >
                        Go to widget
                      </InteractiveText>
                    </Group>
                    {!isMobile && <img src="/images/icons/secure-box.png" alt="secure-box" width={127} height={30} />}
                  </Group>
                </Container>
              </Container>
            </button>
          </div>
        </div>
      </div>
      <div
        className="box-widget pt-xs-12 pb-xs-6
       pb-md-10"
      >
        <Container fluid="xxl">
          <Container fluid="xl">
            <Flex
              className="above-widget pl-xs-2 pr-xs-2"
              justifyContent="space-between"
              alignItems="flex-start"
              baseRole={"group"}
              mobileBreakpoint={"lg"}
            >
              <div style={{ maxWidth: "450px" }}>
                <h2 className="header">Skey File Validator</h2>
                <Space space={"space-md"} />
                <p className="s1 subheader">
                  This is the Skey Network document validator. If you have a document by the Skey Network brand, you can
                  verify its authenticity and validity here.
                </p>
              </div>
              <img src="/images/icons/file-secure.svg" alt="file-secure" className="d-xs-n d-lg-b" />
            </Flex>
            <Space space="space-xxxl" className="d-xs-n d-lg-b" />
            <Space space="space-lg" className="d-xs-b d-lg-n" />
            <div className="widget-wrapper">
              <iframe src={WIDGET_URL || ""} title="Widget" width={"100%"} height={"100%"} />
            </div>
            <Space space="space-xxxl" className="d-xs-n d-lg-b" />
            <Space space="space-lg" className="d-xs-b d-lg-n" />
            <Flex
              className="below-widget pl-xs-2 pr-xs-2"
              rowGap={40}
              colGap={40}
              baseRole={"group"}
              mobileBreakpoint={"lg"}
              fullWidth
            >
              <p className="s1 subheader w-xs-100" style={{ maxWidth: "530px" }}>
                This tool verifies the authenticity of documents registered on the blockchain. You see a list of public
                documents by Skey Network. You can also protect your documents against forgery and protect your
                customers against fraud.
              </p>
              <Flex
                className="w-xs-100"
                justifyContent="space-between"
                alignItems="space-between"
                fullWidth
                rowGap={16}
                baseRole={"stack"}
                mobileBreakpoint={"xs"}
              >
                <p className="s1 subheader" style={{ maxWidth: "530px" }}>
                  If you want your community to be sure that they are using verified documents, write to us:
                </p>
                <Group colGap={8}>
                  <a className="primary s1 w-xs-mc" href={`mailto:${MAIL_ADDRESS}?subject=SkeyBox%20-%20question`}>
                    {MAIL_ADDRESS}
                  </a>
                  <button
                    onClick={() => {
                      copyInput(MAIL_ADDRESS);
                      show();
                    }}
                    title="Copy"
                  >
                    <Group colGap={4}>
                      <img src="/images/copy.svg" alt="copy button" width={40} className="copy-btn" />
                      {isVisible && <p className="primary s2">Copied!</p>}
                    </Group>
                  </button>
                </Group>
              </Flex>
            </Flex>
          </Container>
        </Container>
      </div>
    </div>
  );
};

export default OverviewSkeyBoxWidget;

import { Col, Container, Row } from "react-bootstrap";
import Image from "../../components/atoms/Image/Image";
import Link from "../../components/atoms/Link/Link";
import "./OverviewProducts.scss";
import Space from "../../components/atoms/Spacing/Space";
import { useIsMobile } from "../../../hooks/useIsMobile";

const OverviewProducts = () => {
  const isMobile = useIsMobile("lg");

  return (
    <div id="overview-products" className="o-x-hidden">
      <Container fluid="xxl">
        <Container fluid="xl">
          <Row className="main-row">
            <Col xs={{ span: 12, offset: 0, order: 2 }} lg={{ span: 5, offset: 0, order: 1 }} className="content-col">
              <h2 className="header">Products of Skey Blockchain</h2>
              <Space space="space-lg" />
              <p className="s1 subheader">
                We offer modern products based on our blockchain technology with tools and support
              </p>
              <Space space="space-xxl" />
              <Link
                variant="primary"
                url={"/products"}
                target={"_self"}
                className=""
                size="size-normal"
                mode="normal-mode"
              >
                Show All Products
              </Link>
            </Col>
            <Col
              xs={{ span: 12, offset: 0, order: 1 }}
              lg={{ span: 7, offset: 0, order: 2 }}
              xl={{ span: 6, offset: 1 }}
              className="phone-col"
            >
              {isMobile && (
                <Image
                  imgWidth={617}
                  imgHeight={678}
                  imgPath="/images/overview-products-graphic-mobile.png"
                  alt="products phone"
                  className="overview-products-graphic"
                />
              )}
              {!isMobile && (
                <Image
                  imgWidth={678}
                  imgHeight={617}
                  imgPath="/images/overview-products-graphic.png"
                  alt="products phone"
                  className="overview-products-graphic"
                />
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default OverviewProducts;

import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

import { offerModulesMobile, pricingOfferMobile, starterMobile } from "../../../data/productsPricingOfferMobile";
import Space from "../../components/atoms/Spacing/Space";
import Button from "../../components/atoms/Button/Button";

import { useFormsControll } from "../../../contexts/FormsControllContext";
import { useDefaultProduct } from "../../../contexts/DefaultProductContext";

import "./PricingOfferMobile.scss";

const PricingOfferMobile = () => {
  const { openModulesForm } = useFormsControll();
  const { handleDefaultProduct } = useDefaultProduct();

  const orderAction = () => {
    handleDefaultProduct("Skey Box");
    openModulesForm();
  };

  return (
    <div className="product-pricing-mobile">
      {pricingOfferMobile.map((price, index) => (
        <Fragment key={index}>
          <Col xs={12}>
            <h4>{price.title}</h4>
          </Col>
          <Space space="space-lg" />
          <div className="pricing-header">
            {price.rows?.map((offer, key) => {
              return (
                <Col
                  xs={10}
                  md={offer.serviceName === "net price" ? 2 : 11}
                  sm={offer.serviceName === "net price" ? 2 : 11}
                  key={key}
                >
                  <div className={`pricing-header-col ${offer.className || ""}`}>
                    <p className="s2 service-name">{offer.serviceName}</p>
                  </div>
                </Col>
              );
            })}
          </div>
        </Fragment>
      ))}
      <Space space="space-xs" />
      <hr />
      <Space space="space-sm" />
      {starterMobile.map((price, index) => (
        <Fragment key={index}>
          <Row>
            <Col xs={12}>
              <p className="s2 subHeader-starter">{price.subHeader}</p>
            </Col>
            {price.rows?.map((offer, key) => (
              <div className="offer-service" key={key}>
                <Col xs={6}>
                  <p className="s2">{offer.serviceType}</p>
                </Col>
                <Col xs={{ span: 3, offset: 3 }} className="price-offer">
                  <p className="s2 price">{offer.price}</p>
                </Col>
              </div>
            ))}
          </Row>
        </Fragment>
      ))}
      {offerModulesMobile.map((modules, index) => (
        <Fragment key={index}>
          <Space space="space-lg" />
          <Col xs={12} className="modules-title">
            <p className="s2 title">{modules.title}</p>
          </Col>
          <div>
            <Space space="space-sm" />
            {modules.rows?.map((offer, key) => (
              <Fragment key={key}>
                <Row>
                  <Col xs={12}>
                    <p className="s2 subHeader-starter">{offer.subHeader}</p>
                  </Col>
                  <Col xs={8}>
                    <p className="s2 mb-2">{offer.serviceType}</p>
                  </Col>
                  <Col xs={{ span: 2, offset: 2 }} className="price-offer">
                    <p className="s2 price">{offer.price}</p>
                  </Col>
                  {offer.divider === true && <hr />}
                  <Space space="space-xs" />
                </Row>
              </Fragment>
            ))}
          </div>
        </Fragment>
      ))}
      <Space space="space-xxl" />

      <Button className="button-offer" variant="primary" size="size-normal" onClick={orderAction}>
        Order now
      </Button>
    </div>
  );
};

export default PricingOfferMobile;

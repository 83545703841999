import { Col, Container, Row } from "react-bootstrap";
import { footerScrollLinks } from "../../../data/footerScrollToLinks";
import ScrollToBox from "../../components/molecules/ScrollToBox/ScrollToBox";
import Link from "../../components/atoms/Link/Link";
import { LINKED_IN, PRIVACY_POLICY, SKEY_MAIL, SKEY_SUPPORT, TERMS_AND_CONDITIONS } from "../../../constants";
import { socialMedia } from "../../../data/socialMedia";
import WithTooltip from "../../components/molecules/WithTooltip/WithTooltip";
import { telegramChannels } from "../../../data/telegramChannels";
import Space from "../../components/atoms/Spacing/Space";
import Image from "../../components/atoms/Image/Image";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
      <section id="footer">
        <Container fluid="xxl">
          <Container fluid="xl">
            <Row>
              <Col xs={12} lg={4} className="flex-wrap mb-xxl">
                {footerScrollLinks.map((link) => {
                  return (
                    <ScrollToBox
                      boxTitle={link.boxTitle}
                      links={link.links}
                      className={`w-50 mb-sm`}
                      key={Math.random()}
                    />
                  );
                })}
              </Col>
              <Col xs={12} lg={8}>
                <div className="flex-wrap">
                  <Col xs={12} xl={6} className="as-row mb-lg flex-wrap">
                    <p className="s2 title mr-xs">Check out our team on</p>
                    <Link
                      url={LINKED_IN}
                      icon="/images/linkedin.svg"
                      iconHovers={false}
                      className="linked-in"
                      variant="trietary"
                      mode="dark-mode"
                      size="size-small"
                    >
                      LinkedIn account
                    </Link>
                  </Col>
                  <Col xs={12} className="as-col">
                    <p className="s2 title mb-sm">Follow Us</p>
                    <div className="as-row flex-wrap with-spacing social-media">
                      {socialMedia.map((socialMediaItem) => {
                        return (
                          <a href={socialMediaItem.url} target="blank" rel="norefferer noopener" key={Math.random()}>
                            <img width="31" height="30" src={socialMediaItem.logoPath} alt="social media button" />
                          </a>
                        );
                      })}
                      <WithTooltip
                        alwaysShown={<img width="27" height="30" src="/images/telegram-hover.svg" alt="telegram" />}
                        externalClassForTooltip={"right tooltip-styling"}
                        tooltipInside={
                          <>
                            <p className="s2 mb-sm tooltip-title">Telegram</p>
                            {telegramChannels.map((channel) => {
                              return (
                                <Link
                                  url={channel.url}
                                  className={"social-media-link"}
                                  key={Math.random()}
                                  variant="trietary"
                                  mode="dark-mode"
                                >
                                  {channel.label}
                                </Link>
                              );
                            })}
                          </>
                        }
                      />
                    </div>
                    <Space space="space-xl" />
                  </Col>
                  <Col xs={6} lg={3} className="children-as-white">
                    <p className="s2">Legal disclaimer</p>
                    <Space space="space-md" />
                    <p>Project implemented by</p>
                    <p>the company:</p>
                    <p>Skey Network UAB</p>
                    <p>st. Lvivo g. 105</p>
                    <p>2nd floor</p>
                    <p>08104 Vilnius, Lithuania</p>
                  </Col>
                  <Col xs={6} lg={3} className="children-as-white">
                    <p className="s2">Contact</p>
                    <Space space="space-md" />
                    <p>Information</p>
                    <Link url={`mailto:${SKEY_MAIL}`} variant="trietary" mode="dark-mode" size="size-extra-small">
                      {SKEY_MAIL}
                    </Link>
                    <Space space="space-sm" />
                    <p>Integration and tests of</p>
                    <p>the Skey Network</p>
                    <p>platform</p>
                    <Link url={`mailto:${SKEY_SUPPORT}`} variant="trietary" mode="dark-mode" size="size-extra-small">
                      {SKEY_SUPPORT}
                    </Link>
                  </Col>
                  <Col xs={12} lg={6} className="children-as-white mt-xs-xxl">
                    <Space space="space-xxl" className="mobile-lg" />
                    <p className="s2">Project reporting</p>
                    <Space space="space-md" />
                    <Image imgWidth={54} imgHeight={36} imgPath="/images/europe-flag.svg" alt="europe flag" />
                    <Space space="space-sm" />
                    <p>
                      The company operates in accordance with the European Union law regarding the principles of
                      accounting and reporting. Financial data and risk reporting by a certified auditing company, CIA
                      audit standard (Certified Internal Auditor).
                    </p>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>

      <section id="pdfs">
        <Container fluid="xl" className="as-row space-between flex-wrap pdfs-holder">
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <Space space="space-md" className="mobile-lg" />
            <p className="title">© {new Date().getFullYear()} Skey Network. All Rights Reserved.</p>
          </Col>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 2 }} className="terms-and-conditions">
            <Link url={PRIVACY_POLICY} variant="trietary" mode="dark-mode" size="size-extra-small">
              {"Privacy policy"}
            </Link>
            <Link url={TERMS_AND_CONDITIONS} variant="trietary" mode="dark-mode" size="size-extra-small">
              {"Terms and Conditions"}
            </Link>
            <Link url={"/faq"} target="_self" variant="trietary" mode="dark-mode" size="size-extra-small">
              {"FAQ"}
            </Link>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default Footer;

import { Container } from "react-bootstrap";

import { productsSkeyTixBenefits } from "../../../data/productsSkeyTixBenefits";
import { BENEFITS_TIX } from "../../../constants";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./SkeyTixBenefits.scss";

const SkeyTixBenefits = () => {
  return (
    <div id="skey-tix-benefits" className="skey-tix-benefits">
      <Container fluid="xxl">
        <Container fluid="xl">
          <h2 className="header">Benefits</h2>
          <Space space="space-lg" />

          <div className="benefits-content">
            <div className="list-section">
              <p className="s1 subheader">Check how many advantages of SkeyTix you can use for your event.</p>
              <Space space="space-xxl" className="desktop-lg" />
              <Space space="space-xl" className="mobile-lg" />

              <div className="benefits-list">
                {productsSkeyTixBenefits.map(({ benefit }) => (
                  <div key={Math.random()} className="benefit">
                    <img width={28} height={27} src="/images/oval-benefits.png" alt="benefit point" />
                    <p className="s2 benefit-text">{benefit}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="places-of-application-card">
              <p className="s1 card-title">Places of application</p>
              <Space space="space-xl" />
              <p className="s2">
                Concerts, festivals, sports events, fairs, conferences, closed meetings, discussion groups or
                communities, and many others.
              </p>
              <Space space="space-xl" />
              <Link
                url={BENEFITS_TIX}
                variant="trietary"
                size="size-small"
                icon="/images/telegram-hover.svg"
                imgHeight={18}
                imgWidth={18}
              >
                ask a question on Telegram
              </Link>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixBenefits;

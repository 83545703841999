export const infoSections = ["skey-network", "go-2-nft", "caruma"] as const;

export const skeyNetworkBoxKeys = ["skey-box", "skey-tix", "skey-rwa", "skey-access"] as const;

export const go2nftBoxKeys = ["mobile-app"] as const;
export const carumaBoxKeys = ["passport", "one-key"] as const;

export interface SectionTitleProps {
  imgSrc: string;
  title: string;
  description: string;
}

export interface BoxProps {
  title: string;
  description: string;
  pdfsUrl: string[];
  ytUrls: string[];
  appUrls?: {
    googlePlay?: string;
    appStore?: string;
  };
}

export const sectionsTitles: Record<typeof infoSections[number], SectionTitleProps> = {
  "skey-network": {
    imgSrc: "/images/info/skey-logo.svg",
    title: "Skey Network",
    description:
      "Discover the advantages of blockchain technology and build your services using tools from Skey Network"
  },
  caruma: {
    imgSrc: "/images/info/caruma-logo.svg",
    title: "Caruma",
    description: "Building Trust in Business and Government with SkeyNetwork Blockchain Ecosystem"
  },
  "go-2-nft": {
    imgSrc: "/images/info/go2nft-logo.svg",
    title: "Go2NFT",
    description: "Go2NFT connects physical products with NFC/NFT technology, giving them a digital value"
  }
};

export const skeyNetworkBoxValues: Record<typeof skeyNetworkBoxKeys[number], BoxProps> = {
  "skey-access": {
    title: "Skey Access",
    description: "access control system",
    pdfsUrl: ["", ""],
    ytUrls: ["", "https://www.youtube.com/watch?v=6i60NaojDHM"],
    appUrls: {
      googlePlay: "https://play.google.com/store/apps/details?id=network.skey.access&hl=pl",
      appStore: "https://apps.apple.com/gb/app/skey-access/id1637443765"
    }
  },
  "skey-box": {
    title: "Skey Box",
    description: "B2B/B2A blockchain toolkit",
    pdfsUrl: ["/pdfs/info/skey-box/skey-box-1.pdf", "/pdfs/info/skey-box/skey-box-2.pdf"],
    ytUrls: ["https://www.youtube.com/watch?v=lIbAk9dNWFc", "https://www.youtube.com/watch?v=agGUOVbvJ5g"]
  },
  "skey-rwa": {
    title: "Skey RWA",
    description: "tokenization of assets and physical goods",
    pdfsUrl: ["", ""],
    ytUrls: ["https://youtu.be/BxyEU7wVCjI", "https://youtu.be/Vb9djZDYRgI"]
  },
  "skey-tix": {
    title: "Skey Tix",
    description: "NFT ticketing system",
    pdfsUrl: ["/pdfs/info/skey-tix/skey-tix-1.pdf", "/pdfs/info/skey-tix/skey-tix-2.pdf"],
    ytUrls: ["https://www.youtube.com/watch?v=FE3siUDY7n8", "https://www.youtube.com/watch?v=AXTePjs55zM"]
  }
};

export const go2nftBoxValues: Record<typeof go2nftBoxKeys[number], BoxProps> = {
  "mobile-app": {
    title: "Go2NFT Mobile App",
    description: "digital product identity for fashion, lifestyle and entertainment.",
    pdfsUrl: ["/pdfs/info/go-2-nft/go2nft-1.pdf", "/pdfs/info/go-2-nft/go2nft-2.pdf"],
    ytUrls: ["https://www.youtube.com/watch?v=tsV0oEban3Q", "https://www.youtube.com/watch?v=xMYmWbFcU9M"],
    appUrls: {
      googlePlay: "https://play.google.com/store/search?q=go2nft&c=apps&hl=pl",
      appStore: "https://apps.apple.com/pl/app/go2nft/id1631507369?l=pl"
    }
  }
};

export const carumaBoxValues: Record<typeof carumaBoxKeys[number], BoxProps> = {
  passport: {
    title: "Digital Product Passport",
    description: "Transparency for a sustainable future",
    pdfsUrl: ["", "/pdfs/info/digital-product-passport/caruma-dpp.jpg"],
    ytUrls: ["", ""]
  },
  "one-key": {
    title: "One Key by Caruma",
    description: "We open the future. Caruma presents virtual access keys based on the Blockchain technology",
    pdfsUrl: ["", ""],
    ytUrls: ["", ""],
    appUrls: {
      googlePlay: "https://play.google.com/store/apps/details?id=io.caruma.onekey&hl=pl",
      appStore: "https://apps.apple.com/pl/app/one-key-by-caruma/id1604196352"
    }
  }
};

import { Container } from "react-bootstrap";

import ShadowBox from "../../components/ShadowBox/ShadowBox";
import Stack from "../../../components/arrangement/Stack/Stack";
import Button from "../../components/atoms/Button/Button";
import Group from "../../../components/arrangement/Group/Group";

import { useIsMobile } from "../../../hooks/useIsMobile";

import { ARI_WIDGET_ID } from "../../../config";

import useBuyForm from "./hooks/useBuyForm";
import MinimumLabel from "./components/MinimumLabel/MinimumLabel";
import FiatInputLine from "./components/FiatInputLine/FiatInputLine";
import CryptoInputLine from "./components/CryptoInputLine/CryptoInputLine";
import { CRYPTO_CODE } from "./constants/constants";

import "./OverviewBuyBox.scss";
// import useWindowEvent from "../../../hooks/useWindowEvent";

const exchange = (fiatCode: string, cryptoCode: string, fiatAmount: string) => {
  window.dispatchEvent(
    new CustomEvent("ari10-widget-start-transaction-request", {
      detail: {
        buyCurrencyCode: cryptoCode, //token name
        offerMoney: {
          amount: fiatAmount,
          currencyCode: fiatCode,
          offeredCurrencyCode: fiatCode
        }
      }
    })
  );
};

const OverviewBuyBox = () => {
  const isMobile = useIsMobile("md");
  const form = useBuyForm();

  return (
    <div id="overview-buy-box" className="pt-xs-10 pt-md-20">
      <Container fluid="xxl">
        <Container fluid="xl" className="as-col">
          <h2 className="w-xs-100 t-xs-c mb-xs-2 mb-md-4">Buy Skey Token</h2>
          <p
            className="s1 w-xs-100 t-xs-c"
            style={{ maxWidth: "790px", margin: "auto", marginBottom: isMobile ? "24px" : "48px" }}
          >
            Easily purchase SKEY tokens with your preferred payment method. Enter the amount in EUR, USD or PLN, and see
            the equivalent SKEY tokens you will receive.
          </p>

          <div style={{ width: "100%", maxWidth: "790px", margin: "auto" }}>
            <ShadowBox>
              <Stack justifyContent="flex-start" alignItems="center" rowGap={isMobile ? 16 : 24} fullWidth>
                <div className="center-box">
                  <Stack fullWidth rowGap={isMobile ? 24 : 40} alignItems="center">
                    <div>
                      <MinimumLabel
                        fiatCode={form.fiatCode}
                        shouldShake={Number(form.fiatAmount) < form.activeFiatProps.minAmount && !!form.fiatAmount}
                      />
                      <FiatInputLine
                        activeFiatProps={form.activeFiatProps}
                        changeFiatCode={form.updateFiatCode}
                        fiatAmount={form.fiatAmount}
                        updateFiatAmount={form.updateFiatAmount}
                      />
                    </div>
                    <CryptoInputLine
                      calculatedCrypto={form.calculatedCrypto}
                      fiatCode={form.fiatCode}
                      oneFiatUnitPrice={form.oneFiatUnitPrice}
                    />
                    <Button
                      disabled={Number(form.fiatAmount) < form.activeFiatProps.minAmount || !form.calculatedCrypto}
                      variant="primary"
                      size="size-normal"
                      className="buy-btn"
                      onClick={() => exchange(form.fiatCode, CRYPTO_CODE, form.fiatAmount)}
                    >
                      Buy SKEY
                    </Button>
                  </Stack>
                </div>
                <Stack fullWidth alignItems="center" rowGap={16} className="pl-xs-2 pr-xs-2">
                  <p className="op-05 t-xs-c" style={{ maxWidth: "450px" }}>
                    Pay securely and conveniently using Apple Pay, Google Pay, or Blik. Enjoy a seamless checkout
                    experience with our trusted payment partner.
                  </p>
                  <img src="/images/payment-methods/payment-methods.svg" alt="payment-method" />
                  <Group alignItems="center" colGap={4} justifyContent="center">
                    <p className="op-05">powered by:</p>
                    <img src="/images/partner/ari-10.svg" alt="ari-10" />
                  </Group>
                </Stack>
              </Stack>
            </ShadowBox>
          </div>
        </Container>
      </Container>
      <div
        className="ari10-widget-wrapper"
        data-widget-id={ARI_WIDGET_ID}
        style={{ display: "none" }}
        data-widget-language="en"
        data-default-currency={form.fiatCode}
        data-default-fiat={form.fiatCode}
      ></div>
    </div>
  );
};

export default OverviewBuyBox;

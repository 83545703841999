// SKEY UTILS ***************************************
export const BRIDGE = "https://bridge.skey.network";
export const KEEPER = "https://chrome.google.com/webstore/detail/skey-keeper/dcigkldapeipokagfmcjaijmngcagkcp";
export const DATA_SERVICE = "https://skey-network.gitbook.io/skey-network/data-service";
export const ORGANIZATION_MANAGER = "https://organisation.skey.network/";
export const IOT_API = "https://api.iot.skey.network/api";
export const IOT_PANEL = "https://panel-iot-skey-network.web.app/";
export const EXPLORER = "https://explorer.smartkeyplatform.io/";
export const UTILS = "https://utils.skey.dev/info";
export const STATICTICS = "http://statistics.skey.network";

export const SKEY_UTILS_URLS = {
  BRIDGE,
  KEEPER,
  DATA_SERVICE,
  ORGANIZATION_MANAGER,
  IOT_API,
  IOT_PANEL,
  EXPLORER,
  UTILS,
  STATICTICS
};

// SKEY UTILS ***************************************

import { SKEY_TIX_POAP } from "../constants";

export const productsSkeyTixPoap = [
  {
    img: "/images/skey-tix-poap1.png",
    title: "NFT creation",
    description: "Our system makes creating NFTs related to events, such as tickets, certificates, and souvenirs, easy."
  },
  {
    img: "/images/skey-tix-poap2.png",
    title: "Distribution",
    description: "Sending NFTs directly to event attendees and offering them a unique experience."
  },
  {
    img: "/images/skey-tix-poap3.png",
    title: "Skey PoAP",
    description: "The ability to view and manage NFTs through an intuitive interface.",
    link: {
      url: SKEY_TIX_POAP,
      label: "view"
    }
  }
];

import Overview from "../ui/pages/Overview/Overview";
import Products from "../ui/pages/Products/Products";
import ProductsBlockchain from "../ui/pages/ProductsBlockchain/ProductsBlockchain";
import ProductsSkeyAccessControl from "../ui/pages/ProductsSkeyAccessControl/ProductsSkeyAccessControl";
import News from "../ui/pages/News/News";
import Development from "../ui/pages/Development/Development";
import FAQ from "../ui/pages/FAQ/FAQ";
import NotFound from "../ui/pages/NotFound/NotFound";
import WithNavbarAndFooter from "../ui/layouts/WithNavbarAndFooter";
import Cooperation from "../ui/pages/Cooperation/Cooperation";
import NoLayout from "../ui/layouts/NoLayout";
import ProductsSkeyTix from "../ui/pages/ProductsSkeyTix/ProductsSkeyTix";
import ProductsSkeyBox from "../ui/pages/ProductsSkeyBox/ProductsSkeyBox";
import Info from "../ui/pages/Info/Info";

import { IRoute } from "../shared/interfaces/IRoute";
import WithInfoNavbarAndFooter from "../ui/layouts/WithInfoNavbarAndFooter";
import { menuLinks } from "../data/menuLinks";

const MAIN_PATH_OF_ARRAY = 0;
const PRODUCT_BLOCKCHAIN = 1;
const PRODUCT_SKEY_ACCESS = 2;
const PRODUCT_SKEY_BOX = 3;
const PRODUCT_SKEY_TIX = 4;

export const routes: IRoute[] = [
  {
    Component: Overview,
    path: menuLinks.overview.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Products,
    path: menuLinks.products.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsBlockchain,
    path: menuLinks.products.paths[PRODUCT_BLOCKCHAIN],
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsSkeyAccessControl,
    path: menuLinks.products.paths[PRODUCT_SKEY_ACCESS],
    Layout: WithNavbarAndFooter
  },
  {
    Component: ProductsSkeyTix,
    path: menuLinks.products.paths[PRODUCT_SKEY_TIX],
    Layout: WithNavbarAndFooter
  },
  {
    Component: News,
    path: menuLinks.news.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Cooperation,
    path: menuLinks.cooperation.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Development,
    path: menuLinks.development.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: Info,
    path: menuLinks.info.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithInfoNavbarAndFooter
  },
  {
    Component: FAQ,
    path: menuLinks.faq.paths[MAIN_PATH_OF_ARRAY],
    Layout: WithNavbarAndFooter
  },
  {
    Component: NotFound,
    path: menuLinks.notFound.paths[MAIN_PATH_OF_ARRAY],
    Layout: NoLayout
  },
  {
    Component: ProductsSkeyBox,
    path: menuLinks.products.paths[PRODUCT_SKEY_BOX],
    Layout: WithNavbarAndFooter
  }
].map((route, id) => ({ ...route, id }));

export default routes;

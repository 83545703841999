import {
  PRODUCT_BLOCKCHAIN_GITBOOK,
  PRODUCT_BLOCKCHAIN_PANEL_MANUAL,
  PRODUCT_BLOCKCHAIN_VIDEO
} from "../constants";
import { ITechButton } from "../shared/interfaces/ITechButton";

export const productBlockchainDocumentationLinks: ITechButton[] = [
  {
    label: "Show Gitbook",
    link: PRODUCT_BLOCKCHAIN_GITBOOK,
    iconPath: "/images/gitbook.svg"
  },
  {
    label: "Watch Video",
    link: PRODUCT_BLOCKCHAIN_VIDEO,
    iconPath: "/images/video.svg"
  },
  {
    label: "Show Tools",
    link: PRODUCT_BLOCKCHAIN_PANEL_MANUAL,
    iconPath: "/images/whitepaper.svg",
    self: true
  }
];

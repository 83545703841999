import { SKEY_UTILS_URLS } from "../constants/constants";
import { IProductCard } from "../shared/interfaces/IProductCard";

export const developmentProducts: IProductCard[] = [
  {
    title: "Skey Bridge",
    text: "The Skey Bridge is designed for companies, corporations, and city authorities that use our technology. The swapping process requires the connection of Skey Keeper and Metamask Wallets. Metamask is the wallet from which the ERC20 tokens will be taken, and Skey Keeper is the wallet into which the technical tokens will be sent after the swap.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.BRIDGE
  },
  {
    title: "Skey Keeper",
    text: "This is a crypto wallet extension that enables users to interact with apps operating within the Skey Network ecosystem. It can store Skey technical tokens (called SkeyUSD). This tool is compatible with WEB 3.0.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.KEEPER
  },
  {
    title: "Data service",
    text: "A publicly available server that each Supplier can optionally use in its server infrastructure. This tool allows the implementation of advanced filtering, sorting and aggregating data.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.DATA_SERVICE
  },
  {
    title: "Organisation Manager",
    text: "A web application allowing management of a type of Skey network accounts called Organisations. Organisations allow other accounts - members of the Organisation - to use Skey Network keys present in the Organisations' wallet.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.ORGANIZATION_MANAGER
  },
  {
    title: "IOT Server, IOT API",
    text: "Software for managing devices in the IOT network, exchanging data with the blockchain and for their configuration",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.IOT_API
  },
  {
    title: "IOT Panel",
    text: "A web application dedicated to the configuration and management of IOT devices in the Skey network.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.IOT_PANEL
  },
  {
    title: "Skey Network Explorer",
    text: "Online service that displays the Skey Network blockchain data in understandable form for users - with searching, pagination, and filtering options.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.EXPLORER
  },
  {
    title: "Skey Utils",
    text: "A set of helpful tools for transaction handling, token burning and issuing, script setting, and much more.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.UTILS
  },
  {
    title: "Skey Statistics",
    text: "The tool provides an interactive way to monitor and analyze network activity. Users can easily track the daily number of transactions, as well as the distribution of various types of activity, such as NFT token mining, token transfers, and smart contract interactions.",
    linkLabel: "see more",
    linkImgPath: "/images/go-to.svg",
    url: SKEY_UTILS_URLS.STATICTICS
  }
];

import { Container } from "react-bootstrap";

import { productsSkeyTixPoap } from "../../../data/productsSkeyTixPoap";

import Space from "../../components/atoms/Spacing/Space";
import Link from "../../components/atoms/Link/Link";

import "./SkeyTixPoap.scss";

const SkeyTixPoap = () => {
  return (
    <div id="skey-tix-poap" className="skey-tix-poap">
      <Container fluid="xxl">
        <Container fluid="xl">
          <div className="skey-tix-poap-header">
            <h2>Creating and displaying NFTs with Skey PoAP</h2>
            <Space space="space-xxl" />
          </div>

          <div className="skey-tix-poap-cards">
            {productsSkeyTixPoap.map(({ img, title, description, link }) => {
              return (
                <div key={Math.random()} className="poap-card">
                  <img src={img} alt={title} width={300} height={143} className="card-image" />
                  <Space space="space-md" />
                  <span>{title}</span>
                  <Space space="space-sm" />
                  <span>{description}</span>
                  {link && (
                    <>
                      <Space space="space-sm" />
                      <Link
                        imgWidth={16}
                        imgHeight={15}
                        url={link.url}
                        target="_blank"
                        variant="trietary"
                        size="size-small"
                        icon="/images/go-to.svg"
                      >
                        {link.label}
                      </Link>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default SkeyTixPoap;

import { Container, Row } from "react-bootstrap";

import { SKEY_STAKING } from "../../../../constants";
import { menuLinks } from "../../../../data/menuLinks";

import Group from "../../../../components/arrangement/Group/Group";
import MenuBar from "../../molecules/MenuBar/MenuBar";
import Image from "../../atoms/Image/Image";
import Link from "../../atoms/Link/Link";

import "./Navbar.scss";

const Navbar = () => {
  return (
    <>
      <div className="navbar-background"></div>
      <section id="nav-bar">
        <Container fluid="xl">
          <Row className="row-center">
            <Image
              imgWidth={104}
              imgHeight={36}
              imgPath={"/images/skey-logo-basic.svg"}
              alt={"skey logo"}
              asLink={true}
              href={menuLinks.overview.paths[0]}
            />
            <Group justifyContent="space-between" fullHeight colGap={10} className="w-auto">
              <Link
                imgHeight={35}
                imgWidth={121}
                url={SKEY_STAKING}
                icon="/images/skey-staking-logo.svg"
                variant="trietary"
                className="p-0 rightNav"
                iconOnLeft
              >
                {""}
              </Link>
              <MenuBar />
            </Group>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Navbar;

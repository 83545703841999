import ProductsHero from "../../sections/ProductsHero/ProductsHero";
import ProductsSkeyCards from "../../sections/ProductsSkeyCards/ProductsSkeyCards";
import ProductsDeflationSystem from "../../sections/ProductsDeflationSystem/ProductsDeflationSystem";
import OverviewDocumentation from "../../sections/OverviewDocumentation/OverviewDocumentation";
import ContactProductDefault from "../../sections/ContactSection/ContactProductDefault/ContactProductDefault";

import "./Products.scss";

const Products = () => {
  return (
    <section id="products">
      <ProductsHero />
      <ProductsSkeyCards />
      <ProductsDeflationSystem />
      <OverviewDocumentation />
      <ContactProductDefault />
    </section>
  );
};

export default Products;

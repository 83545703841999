import { IOneStory } from "../shared/interfaces/IOneStory";

export const newsStoriesOfTheProject: IOneStory[] = [
  {
    year: "2023",
    cards: [
      {
        date: "June 2023",
        title: "Kleiberit enters into a partnership with Skey Network.",
        description:
          "The advantages of Skey Network technology, such as transparency, document authentication, and product certification, are aspects valued by Kleiberit.",
        url: "https://twitter.com/SkeyNetwork/status/1673295898997149698"
      },
      {
        date: "June 2023",
        title: "Skey Network supported on Trezor.",
        description: "The Skey Network blockchain has been backed by the hardware wallet Trezor.",
        url: "https://trezor.io/coins/detail/skey-network"
      },
      {
        date: "June 2023",
        title: "Web3 Berlin Conference",
        description:
          "Skey Network technology was presented on the main stage at Web3 Berlin. Conversations with numerous companies have paved the way for potential collaborations and further implementations of our solutions.",
        url: "https://twitter.com/SkeyNetwork/status/1668596119251611648"
      },
      {
        date: "May 2023",
        title: "Skey token staking on Kucoin.",
        description: "Skey token staking in partnership with Kucoin has launched.",
        url: "https://twitter.com/SkeyNetwork/status/1663123815071612928"
      },
      {
        date: "April 2023",
        title: "Marshal’s Office in Olsztyn uses SkeyTix",
        description:
          "The Office organized the event, generated tickets, and configured the API thanks to the SkeyTix panel. The result was significant time and cost savings.",
        url: "https://twitter.com/SkeyNetwork/status/1644255221151850496"
      },
      {
        date: "February 2023",
        title: "Waste24 integrates SkeyBox",
        description:
          "Our partner Waste24 installed the node and accessed the Skey Network. They use tools for website security, certificates, file security, NFT generator, supply chain, security, and transparency of company data.",
        url: "https://www.youtube.com/watch?v=rdx4NVTKqgU"
      },
      {
        date: "February 2023",
        title: "SkeyTix used at the conference.",
        description:
          "SkeyTx was used at the Conference in Hotel Park Olsztyn. NFT tickets in practical use worked excellently and were very easy to use!",
        url: "https://twitter.com/SkeyNetwork/status/1624042244805128192"
      },
      {
        date: "January 2023",
        title: "Koenigsegg Extremetech-Day.",
        description:
          "Skey Network was the only one to present technologies based on blockchain and NFT on Koenigsegg Extremetech-Day. We presented concepts of using our technologies in future cars.",
        url: "https://twitter.com/SkeyNetwork/status/1618624985106624514"
      }
    ],
    initialVariant: "active"
  },
  {
    year: "2022",
    cards: [
      {
        date: "November 2022",
        title: "Virtual Power Plant partnership",
        description:
          "Skey Network has announced a partnership with Virtual Power Plant (VPPlant), a leading provider of AI technology for energy efficiency in buildings. The goal is to use blockchain to increase trust and transparency in energy generation from renewable sources while reducing costs.",
        url: "https://skeynetwork.medium.com/skey-network-ecosystem-in-the-energy-sector-93feb167d596"
      },
      {
        date: "August 2022",
        title: "Skey WhitePaper 2.2 updated",
        description:
          "The most crucial changes concern Tokenomics, wallet division and locking of tokens. Our latest products - the Skey Access application and tools of our ecosystem - Skey Keeper and Skey Bridge - are also presented.",
        url: "https://skey.network/white-papers/SkeyNetwork-WP-EN-LATEST.pdf"
      },
      {
        date: "July 2022",
        title: "Skey Tokenomics - Burning Tokens",
        description:
          "992 292 Skey was burned. These are all tokens from finished implementations. Each subsequent token will be automatically burned after the SWAP on the Skey Bridge.",
        url: "https://twitter.com/SkeyNetwork/status/1547905021592801281"
      },
      {
        date: "June 2022",
        title: "World Urban Forum",
        description:
          "Skey Network technology was presented at the World Urban Forum. It is one of the world's most meaningful conferences about Smart Cities.",
        url: "https://www.facebook.com/watch/?v=640127597387319"
      },
      {
        date: "May 2022",
        title: "The first city was launched on the Skey Network.",
        description:
          "We announced implementations that would involve the use of Skey tokenomics. The first of many cities started operating this year. Welcome, Kołobrzeg! ",
        url: "https://skeynetwork.medium.com/the-first-city-operating-on-the-skey-network-welcome-ko%C5%82obrzeg-b2f3b98caa9c"
      },
      {
        date: "March 2022",
        title: "Burning Investment",
        description:
          "Skey Network has made a strategic investment of 5000000 USD in SKEY. All 70721357.85 (7.07% of total supply) SKEY tokens were burned. Address of burned tokens:",
        url: "https://etherscan.io/tx/0x1a438f6549c907abb1ec6c6c25742b93b93d69f4388cf46e259640908a9c37ae"
      }
    ],
    initialVariant: "default"
  },
  {
    year: "2021",
    cards: [
      {
        date: "December 2021 - March 2022",
        title: "Skey Network Challenge",
        description:
          "Skey Network Challenge was a contest that gave companies, start-ups, and individual developers numerous opportunities—to generate other innovative ideas, concepts, and prototypes to use via The Skey Network blockchain ecosystem. Over 300 projects from over 64 countries took part in the competition. We are also open to new ideas and collaborations.",
        url: "https://skeynetwork.medium.com/summary-of-the-skey-challenge-517e95eea8d8"
      },
      {
        date: "November 2021",
        title: "Mainnet Launch",
        description: "Mainnet is an independent branch of the Skey Network blockchain dedicated to production usage. ",
        url: "https://skeynetwork.medium.com/mainnet-launch-ae6231197175"
      },
      {
        date: "Septemer 2021",
        title: "Smartkey evolution into Skey Network",
        description: "An evolution from platform solutions service to the Own Network.",
        url: "https://skeynetwork.medium.com/smartkey-evolution-from-platform-solutions-service-to-the-own-network-396d5193a758"
      },
      {
        date: "October 2021",
        title: "Future Blockchain Summit",
        description: "Keynote speeches and panels regarding actual use cases of Skey blockchain in IoT.",
        url: "https://skeynetwork.medium.com/skey-network-dubai-conferences-summary-8143a6499226"
      },
      {
        date: "October 2021",
        title: "Crypto Expo Dubai",
        description: "Skey Network was selected as the best IoT solution in Crypto at Crypto Expo Dubai.",
        url: "https://skeynetwork.medium.com/smartkey-opens-the-new-season-with-a-bang-now-dubai-d903ce870b2a"
      },
      {
        date: "June 2021",
        title: "Orange Poland Headquarters.",
        description: "Meetings with Orange Poland IoT. Cooperation and partnership development",
        url: "https://skeynetwork.medium.com/smartkey-x-orange-the-worlds-first-blockchain-of-things-sim-for-smart-cities-9e7edad42d28"
      },
      {
        date: "May 2021",
        title: "AIBC Summit Dubai",
        description: "Series of meetings and an interview for NASDAQ."
      }
    ],
    initialVariant: "default"
  },

  {
    year: "2020",
    cards: [
      {
        date: "Seoul · 2020",
        title: "OASISBloc",
        description: "Project partner invitation. We have signed 2 contracts with this partner."
      }
    ],
    initialVariant: "default"
  },
  {
    year: "2019",
    cards: [
      {
        date: "Seoul · 2019",
        title: "KBW Seoul Conference 2019",
        description: "Presentations and conclusion of the first collaboration agreement."
      },
      {
        date: "Berlin · 2019",
        title: "TOA Berlin",
        description: "Joint presentation with Waves at TOA Berlin."
      },
      {
        date: "London · 2019",
        title: "Waves Headquarters",
        description: "Live presentation for the London community at Primalbase Waves office."
      },
      {
        date: "Warsaw · 2019",
        title: "Mercedes HQ Poland.",
        description: "Presentation of Blockchain solutions for the the automotive industry."
      }
    ],
    initialVariant: "default"
  }
];

import { Container } from "react-bootstrap";

import { SKEY_KEEPER, SKEY_TIX_POAP } from "../../../constants";
import { profuctsSkeyTixFaq } from "../../../data/profuctsSkeyTixFaq";

import Space from "../../components/atoms/Spacing/Space";
import AccordionV2 from "../../components/Accordion/AccordionV2";
import Link from "../../components/atoms/Link/Link";

import "./SkeyTixFaq.scss";

const SkeyTixFaq = () => {
  const baseProfuctsSkeyTixFaq = profuctsSkeyTixFaq.slice(0, 2);

  return (
    <div id="skey-tix-faq" className="skey-tix-faq">
      <Container fluid="xl">
        <h2 className="skey-tix-faq-title">NFT FAQ</h2>
        <Space space="space-xxl" />

        <AccordionV2>
          {baseProfuctsSkeyTixFaq?.map(({ question, answer }, index) => {
            return (
              <AccordionV2.Tab key={index}>
                <AccordionV2.Summary>{question}</AccordionV2.Summary>
                <AccordionV2.Details>
                  <p className="s2">{answer as any}</p>
                </AccordionV2.Details>
              </AccordionV2.Tab>
            );
          })}

          <AccordionV2.Tab>
            <AccordionV2.Summary>{profuctsSkeyTixFaq[2].question}</AccordionV2.Summary>
            <AccordionV2.Details>
              <p className="s2">
                Want to find an NFT from a mega event? No problem! Hit up{" "}
                <Link variant="trietary" target="_blank" url={SKEY_TIX_POAP} className="nft-faq-link">
                  Skey Tixplorer
                </Link>{" "}
                - it's the place where the magic happens. Once you're there, you use the search engine. You type in your
                NFT address or wallet address, and boom! All the fabulous NFTs from the event are at your fingertips.
                Just like in a computer game when you find hidden treasure. You watch, choose, and enjoy the digital
                souvenir. Simple as taking a selfie!
              </p>
            </AccordionV2.Details>
          </AccordionV2.Tab>

          <AccordionV2.Tab>
            <AccordionV2.Summary>{profuctsSkeyTixFaq[3].question}</AccordionV2.Summary>
            <AccordionV2.Details>
              <ol className="nft-faq-list">
                <li>Click on the link that came in the email - it's like getting into a VIP party.</li>
                <li>
                  Once you're there, look for the "Claim" button. Click it, and you'll see 15 words - they're like game
                  codes, so write them down well!
                </li>
                <li>
                  Now go to Chrome and install the wallet from here:{" "}
                  <Link variant="trietary" target="_blank" url={SKEY_KEEPER} className="nft-faq-link">
                    Skey Keeper
                  </Link>{" "}
                  It's like adding a new app to your smartphone.
                </li>
                <li>Then, set up a password for your wallet - like securing your diary from curious siblings.</li>
                <li>
                  Import the wallet using those 15 words you saved earlier (Add account{">"} Import account {">"} Paste
                  seed {">"} Import).
                </li>
                <li>
                  Go to{" "}
                  <Link variant="trietary" target="_blank" url={SKEY_TIX_POAP} className="nft-faq-link">
                    Skey Tixplorer
                  </Link>{" "}
                  and browse the NFTs
                </li>
              </ol>
            </AccordionV2.Details>
          </AccordionV2.Tab>

          <AccordionV2.Tab>
            <AccordionV2.Summary>How to transfer my NFT to another wallet?</AccordionV2.Summary>
            <AccordionV2.Details>
              <p className="s2">To transfer the received NFTs from the event to another wallet follow these steps:</p>
              <ol className="nft-faq-list mt-xs-1">
                <li>
                  Connect your wallet on the website -{" "}
                  <Link variant="trietary" target="_blank" url={SKEY_TIX_POAP} className="nft-faq-link">
                    Skey Tixplorer
                  </Link>
                </li>
                <li>Select the NFTs you want to send</li>
                <li>Enter the address of the wallet to receive the NFT.</li>
                <li>Send</li>
              </ol>
            </AccordionV2.Details>
          </AccordionV2.Tab>
        </AccordionV2>
      </Container>
    </div>
  );
};

export default SkeyTixFaq;

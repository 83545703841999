import { AppStage } from "./shared/types/AppStage";

export const LINKED_IN = "https://www.linkedin.com/company/skeynetwork";
export const TWITTER = "https://twitter.com/skeynetwork";
export const YOUTUBE = "https://www.youtube.com/channel/UC6oK-S1xW9yysYFAXBou6sA";
export const REDDIT = "https://www.reddit.com/r/skeynetwork/";
export const FACEBOOK = "https://www.facebook.com/SkeyNetwork";
export const DISCORD = "https://discord.com/invite/en7YQU4EBR";
export const KAKAO_TALK = "https://open.kakao.com/o/g9fgzwPc";
export const MEDIUM = "https://skeynetwork.medium.com/";
export const SKEY_BOX = "https://box.skey.network/";
export const BENEFITS_TIX = "https://t.me/SkeyTix_Team";

export const TIX_EVENT = "https://panel.tix.skey.network/";
export const SKEY_TIX_POAP = "https://panel.tix.skey.network/poap-nft";
export const SKEY_KEEPER =
  "https://chrome.google.com/webstore/detail/skey-keeper/dcigkldapeipokagfmcjaijmngcagkcp?utm_source=ext_sidebar&hl";

export const SKEY_TIX_APP_IOS = "https://apps.apple.com/us/app/skeytix/id1662473117";
export const SKEY_TIX_APP_ANDROID = "https://play.google.com/store/apps/details?id=network.skey.nftticket.app&pli=1";

export const TELEGRAM_1 = "https://t.me/SkeyNetworkNews";
export const TELEGRAM_2 = "https://t.me/SkeyNetworkChat";
export const TELEGRAM_3 = "https://t.me/SmartKeyChat_Korea";
export const TELEGRAM_4 = "https://t.me/SkeyNetworkChatPL";
export const TELEGRAM_5 = "https://t.me/SmartKeyChatFr";
export const TIX_URL = "https://skeynetwork.medium.com/skey-tix-the-ticket-of-the-future-d9955b5160da";

export const SKEY_MAIL = "office@skey.network";
export const SKEY_SUPPORT = "support@skey.network";

export const EXCHANGE_UNISWAP =
  "https://www.dextools.io/app/ether/pair-explorer/0xf346d00965776e504930675100c8e2871bd6530d";
export const EXCHANGE_PROBIT = "https://www.probit.com/app/exchange/SKEY-USDT";
export const EXCHANGE_KUCOIN = "https://www.kucoin.com/trade/SKEY-USDT";
export const EXCHANGE_BITMART = "https://www.bitmart.com/trade/en-US?symbol=SKEY_USDT";

export const SKEY_TOKEN_ADDRESS = "0x06A01a4d579479Dd5D884EBf61A31727A3d8D442";
export const SKEY_ETHERSCAN = "https://etherscan.io/token/0x06A01a4d579479Dd5D884EBf61A31727A3d8D442";
export const SKEY_TOKEN_SYMBOL = "Skey";
export const SKEY_TOKEN_DECIMALS = 8;
export const SKEY_BOOKLET = "/downloads/Skey-Network-Booklet.pdf";

export const SKEY_LOGO_PACK = "/downloads/logo-pack.pdf";
export const SKEY_IMAGES = "/downloads/images.zip";
export const WHITE_PAPER = "/white-papers/SkeyNetwork-WP-EN-LATEST.pdf";
export const LOGO_BLACK_BG = "/downloads/logo.png";
export const SECURITY_AUDIT = "/smartkey-audit-report.pdf";
export const TERMS_AND_CONDITIONS = "/downloads/Terms-and-conditions-Skey-Network.pdf";
export const PRIVACY_POLICY = "/downloads/Privacy-Policy-for-Skey-Network.pdf";
export const WEBSITE_REGULATIONS = "/regulations.pdf";
export const FAQ = "/downloads/faq.pdf";
export const SKEY_GITBOOK = "https://skey-network.gitbook.io/skey-network/";

export const PRODUCT_BLOCKCHAIN_GITBOOK = SKEY_GITBOOK;
export const PRODUCT_BLOCKCHAIN_VIDEO = "https://www.youtube.com/watch?v=DkIxgcG7aZ8";
export const PRODUCT_BLOCKCHAIN_PANEL_MANUAL = "/development";

export const PRODUCT_SKEY_ACCESS_GITBOOK = SKEY_GITBOOK;
export const PRODUCT_SKEY_ACCESS_VIDEO = "https://www.youtube.com/watch?v=Vd-TD9dfB2M&t=8s";
export const PRODUCT_SKEY_ACCESS_ORGANIZATION_MANAGER_MANUAL =
  "/downloads/Skey%20Network%20Organization%20Manager%20-%20User%20Manual.pdf";
export const PRODUCT_SKEY_ACCESS_SKEEPER_MANUAL = "/downloads/Skey%20Network%20Skeeper%20-%20User%20Manual.pdf";
export const PRODUCT_SKEY_TIX_GITBOOK = "https://tix.api.skey.dev/";
export const PRODUCT_SKEY_TIX_VIDEO = "https://www.youtube.com/watch?v=FE3siUDY7n8";
export const PRODUCT_SKEY_TIX_PANEL_MANUAL =
  "https://skeynetwork.medium.com/skey-tix-the-ticket-of-the-future-d9955b5160da";

export const SKEY_WIZARD = "https://box.skey.network/";
export const SKEY_STAKING = "https://staking.skey.network/";

export const APP_STAGE: AppStage = (process.env.REACT_APP_STAGE as AppStage) || "dev";

const MAILING_SERVICE_URL_PROD = process.env.REACT_APP_MAILING_SERVICE_URL_PROD || "Unhandled";
const MAILING_SERVICE_URL_DEV = process.env.REACT_APP_MAILING_SERVICE_URL_DEV || "Unhandled";

const MAILING_CAPTCHA_KEY_PROD = process.env.REACT_APP_MAILING_RECAPTCHA_KEY_PROD || "Unhandled";
const MAILING_CAPTCHA_KEY_DEV = process.env.REACT_APP_MAILING_RECAPTCHA_KEY_DEV || "Unhandled";

const WIDGET_URL_DEV = process.env.REACT_APP_SKEY_BOX_WIDGET_URL_DEV;
const WIDGET_URL_PROD = process.env.REACT_APP_SKEY_BOX_WIDGET_URL_PROD;

export const MAILING_SERVICE_URL = () => {
  if (APP_STAGE === "dev") {
    return MAILING_SERVICE_URL_DEV;
  } else if (APP_STAGE === "prod") {
    return MAILING_SERVICE_URL_PROD;
  } else {
    return "provide mailing service url";
  }
};

export const CAPTCHA_KEY = () => {
  if (APP_STAGE === "dev") {
    return MAILING_CAPTCHA_KEY_DEV;
  } else if (APP_STAGE === "prod") {
    return MAILING_CAPTCHA_KEY_PROD;
  } else {
    return "provide captcha key";
  }
};

export const MAILING_API_KEY = process.env.REACT_APP_MAILING_SERVICE_API_KEY_PROD || "provide service url";

export const MAILING_SERVICE_TIMEOUT = 8000; //[ms]

export const WIDGET_URL =
  APP_STAGE === "dev" && WIDGET_URL_DEV
    ? WIDGET_URL_DEV
    : APP_STAGE === "prod" && WIDGET_URL_PROD
    ? WIDGET_URL_PROD
    : null;

// SKEY BOX DOCUMENTS
export const SKEY_BOX_TERMS_AND_CONDITIONS = "/downloads/skeyBox/Terms-and-Conditions-SkeyBox.pdf";
export const SKEY_BOX_PRIVACY_POLICY = "/downloads/skeyBox/Privacy-Policy-and-Personal-Data-Protection.pdf";
